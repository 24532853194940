.rdrCalendarWrapper {
  @apply select-none;
}

.rdrMonthAndYearWrapper {
  @apply py-[15px] flex justify-between border-b border-b-[#F5F6F8];
}

.rdrMonth {
  @apply py-4;
}

.rdrNextPrevButton {
  @apply block w-6 h-6 p-0 border-0 rounded-lg overflow-hidden bg-[#f8f8f8];
}

.rdrNextPrevButton i {
  @apply block w-0 h-0 p-0 text-center border-[5px] border-transparent m-auto;
}

.rdrPprevButton i {
  @apply border-r-[6px] border-r-black-gray -translate-x-[3px];
}

.rdrNextButton i {
  @apply border-l-[6px] border-r-black-gray translate-x-[3px] rotate-180;
}

.rdrMonths {
  @apply flex;
}

.rdrMonthsVertical {
  @apply flex-col;
}

.rdrDay {
  @apply aspect-square bg-[#F5F6F8] rounded-[calc(100%*0.375)];
}

.rdrDay:hover {
  @apply bg-accent-primary;
}

.rdrDay:hover span {
  @apply text-white;
}

.rdrDays {
  @apply text-sm;
}

.rdrWeekDays {
  @apply text-[10px] mb-5;
}

.rdrDays,
.rdrWeekDays {
  @apply grid grid-cols-7 text-center gap-y-[18px] gap-x-[calc(100%*0.06)];
}

.rdrSelected,
.rdrDayNumber {
  @apply absolute top-0 left-0 h-full w-full;
}

.rdrSelected {
  @apply bg-accent-primary z-0 rounded-[calc(100%*0.375)];
}

.rdrSelected ~ .rdrDayNumber {
  @apply text-white;
}

.rdrDayPassive .rdrSelected {
  @apply hidden;
}

.rdrDayNumber {
  @apply flex justify-center items-center z-1;
}

.rdrDay,
.rdrWeekDay {
  @apply text-center relative;
}

.rdrDayPassive,
.rdrDayDisabled {
  @apply pointer-events-none;
}

.rdrDayPassive .rdrDayNumber span,
.rdrDayDisabled .rdrDayNumber span {
  @apply text-[#c4c4c4];
}
