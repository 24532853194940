@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --expanded-sidenav-width: 250px;
  --collapsed-sidenav-width: 64px;
  --sidenav-animation-duration: 100ms;
}

/* To apply accent color to stroke color of path elements inside an SVG */
.path-stroke-accent path {
  @apply text-accent-primary stroke-current;
}

.path-fill-current path {
  @apply fill-current;
}

.rect-fill-white rect {
  @apply fill-white;
}

.page {
  @apply w-full min-h-[calc(100vh-89px)] bg-neutral-100 p-10 mt-[89px];
}
textarea::placeholder,
input::placeholder,
.placeholder {
  @apply text-light-slate-grey opacity-40;
}

.h-page {
  /* top bar height (89px) + vertical padding (40px for top and bottom) = 137px */
  @apply h-[calc(100vh-169px)];
}

.disabled-button {
  @apply pointer-events-none opacity-60;
}

.skeleton-shimmer {
  @apply relative overflow-hidden bg-gray-200;
}

.skeleton-shimmer::after {
  @apply absolute top-0 left-0 right-0 bottom-0 transform-gpu -translate-x-full;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: '';
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.spinner-animation {
  @apply animate-spin;
  animation-timing-function: ease-in-out;
}

.shadow-default {
  @apply shadow-[0_2px_10px_rgba(0,0,0,0.1)];
}

input[type='checkbox'] {
  @apply accent-accent-primary;
}

.form-label {
  @apply inline-block mb-1.5 text-sm text-light-slate-grey;
}

input,
textarea {
  @apply focus-visible:outline-[#A4A1AE];
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.15);
}

.white-scroll::-webkit-scrollbar {
  width: 0.4em;
}

.white-scroll::-webkit-scrollbar-track {
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(169, 169, 169, 0.7);
  border-radius: 50px;
}

body {
  scrollbar-width: auto;
  scrollbar-color: rgba(169, 169, 169, 0.7) rgba(0, 0, 0, 0.3);
}

.ck.ck-editor__editable {
  @apply text-light-slate-grey;
}
.ck.ck-editor__editable > .ck-placeholder::before {
  @apply opacity-40 text-light-slate-grey;
}
.ck.ck-editor__editable {
  font-family: 'Inter';
  @apply font-normal text-sm
}

.ck.ck-editor__editable ul, .ck.ck-editor__editable ol {
  /* padding-left: 25px; */
  @apply pl-[25px]
}

.ck.ck-editor__editable a {
  @apply text-blue-600 underline
}
.ck.ck-editor__main>.ck-editor__editable {
  /* min-height: 100px; */
  @apply min-h-[100px]
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  @apply border-b-0
}
.disabled_editor .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
  border-top-color: transparent !important;
}

.disabled_editor .ck.ck-editor__editable {
  @apply text-black-gray;
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}
